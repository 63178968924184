import React,{useState,useRef} from "react";
import { StyleSheet, Text, View, ScrollView, ImageBackground, Button, TouchableOpacity,Animated } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Dimensions } from 'react-native';
import AppLoading from 'expo-app-loading';
import { Hoverable, Pressable, } from 'react-native-web-hover'
import { logEvent } from "firebase/analytics";

import { useHover, useFocus, useActive } from 'react-native-web-hooks';
import { CaesarDressing_400Regular } from "@expo-google-fonts/caesar-dressing"
import { useFonts, VarelaRound_400Regular } from '@expo-google-fonts/varela-round';


import { BlurView } from 'expo-blur';
import { DataTable } from 'react-native-paper';

import { TextInput } from 'react-native-paper';
import { AntDesign } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { Header } from "../components/index";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ref, set } from "firebase/database";
import { addUser, analytics, addProblem } from "../firebase/firebase";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
import * as Linking from 'expo-linking';


const isPhone = windowWidth < 600



export function ContactUs({navigation}){




    let [fontsLoaded] = useFonts({
        'Navarone-Regular': require('../assets/fonts/Navarone-Regular.ttf'),VarelaRound_400Regular,CaesarDressing_400Regular
    });

    const bottomColor = '#3b3b3b'
    const backgroundColor = "#f8f9fa"

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [phone, setPhone] = useState('');

    const [isContacted,setContacted] = useState(false)
    
    const _handlePress = () => {

        setContacted(true)
        addUser({
            name: name,
            email:email,
            phone:phone,
        })
        addProblem({
            name: name,
            email: email,
            phone: phone,
            desc:text,
        })
        
        //Linking.openURL("sms:5418177024&body=" + name.replaceAll(" ", "%20") + " needs help with " + text.replaceAll(" ", "%20") + ". Contact them at " + phone + " or " + email );

    };

    const springAnim = useRef(new Animated.Value(1)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.spring(springAnim, {
            toValue: 0,
            duration: 1,

            // return to start
        }).start();
        // Animated.timing(, {
        //     toValue: 1,
        //     duration: 5000,
        // }).start();
    };



    if (!fontsLoaded) {
        return <AppLoading />;
    } else {

        return (

            <ScrollView style={{backgroundColor:backgroundColor}}>
                {isContacted && <View style={{

                    flex: 1,
                    backgroundColor: '#f8f9fa',
                    alignItems: 'center',


                }}>
                    <Header navigation={navigation} isContact />
                    <View style={{
                        marginTop: isPhone ? 10 : 100,
                        width:isPhone?"90%":500,
                        alignItems:"center",
                        padding:20,
                        borderRadius:20,
                        borderWidth:2,
                    }}> 
                        <Text style={{
                            color: "#444",
                            fontWeight: "bold",
                            fontSize: 30,
                            fontFamily: "VarelaRound_400Regular",
                            textAlign: "center",

                            paddingHorizontal: isPhone ? 10 : 40,
                        }}>
                            Thanks for contacting us.{"\n"}It means alot.
                        </Text>
                        <Text style={{
                            fontSize: 20,
                            fontFamily: "VarelaRound_400Regular",
                            textAlign: "center",

                            paddingHorizontal: isPhone ? 10 : 40,
                            marginTop: 10
                        }}>
                            Irure fugiat commodo ex aliquip ea aliquip excepteur. Esse est velit tempor ea officia qui sunt magna exercitation. Nulla pariatur nulla ex magna laboris cupidatat occaecat eu veniam nisi aliquip.
                        </Text>
                    </View>

                </View>}
                {!isContacted &&<View style={{

                    flex: 1,
                    backgroundColor: '#f8f9fa',
                    alignItems: 'center',


                }}>
                    
                    <Header navigation={navigation} isContact />
                    <View style={{
                        margin: 50,
                        marginTop:0,
                        alignItems: "center",
                        width: "100%",

                    }}>
                       
                    </View>


                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}>

                        <View style={{
                            margin: 50,
                            alignItems: "center",
                            width: isPhone?"90%":"40%",

                        }}>
                            <Text style={{
                                color: "#59626a",
                                fontWeight: "bold",
                                fontSize: 30,
                                fontFamily: "VarelaRound_400Regular",
                                textAlign: "center"
                            }}>
                                Please Fill Out Contact Form Below
                            </Text>
                            
                        </View>

                        

                    </View>
                        
                    <View style={{ width: isPhone ? "90%" : "60%", borderWidth: 1, borderRadius: 20, padding: 20, margin: 20, marginBottom: 100, alignItems: isPhone ? "center" : null}}>
                        <TextInput
                            style={{ width: isPhone ? "90%" : "40%", alignSelf: isPhone ? null : "flex-start" }}
                            label="Phone Number"
                            value={phone}
                            mode="outlined"
                            outlineColor="#bbb"
                            activeOutlineColor="#444"
                            right={<TextInput.Icon name="cellphone" />}
                            onChangeText={text => setPhone(text)}
                        />
                        <TextInput
                            style={{ width: isPhone ? "90%" : "40%", alignSelf: isPhone ? null : "flex-start" }}
                            label="Name"
                            value={name}
                            mode="outlined"
                            outlineColor="#bbb"
                            activeOutlineColor="#444"
                            right={<TextInput.Icon name="account" />}
                            onChangeText={text => setName(text)}
                        />
                        <TextInput
                            style={{ width: isPhone ? "90%" : "40%", alignSelf: isPhone ? null : "flex-start" }}
                            label="Email"
                            value={email}
                            mode="outlined"
                            outlineColor="#bbb"
                            activeOutlineColor="#444"
                            right={<TextInput.Icon name="email" />}
                            onChangeText={text => setEmail(text)}
                        />
                        <Text style={{
                            margin:10,
                            color: "#59626a",
                            fontWeight: "bold",
                            fontSize: 24,
                            fontFamily: "VarelaRound_400Regular",
                            textAlign: "center"
                        }}>
                            Now, Please tell us about your problem.
                        </Text>

                        <TextInput
                            style={{ width: "100%", height:windowHeight*0.2 }}
                            multiline
                            label="Your Problem"
                            placeholder="Your Problem That We'll Shortly Fix"
                            value={text}
                            mode="outlined"
                            outlineColor="#bbb"
                            activeOutlineColor="#444"
                            right={<TextInput.Icon name="alert" />}
                            onChangeText={text => setText(text)}
                        />
                        <TouchableOpacity
                            onPress={_handlePress}
                            style={{
                                marginTop: 10,
                            
                                padding: 10,
                                borderRadius: 20,
                                borderWidth: 2,
                                alignSelf:"center"
                            }}

                        >
                            <Text
                                style={{
                                    fontFamily: "VarelaRound_400Regular",
                                    alignSelf: "center",
                                    textAlign: "center",
                                    fontSize: 20,
                                    fontWeight: "600"
                                }}>
                                Contact Us
                            </Text>
                        </TouchableOpacity>
                        
                        
                    </View>
                    
                 </View>}
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    point:{
        backgroundColor: "white",
        width: 5,
        height: 5,
        borderRadius: 5,
        margin: 5
    },
    listItem:{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
    },

    listText:{
        color: 'white',
        fontWeight: "500",
        fontSize: 0.013 * windowWidth,
        fontFamily: "VarelaRound_400Regular"
    },
    headerButtonText:{
        fontFamily: "VarelaRound_400Regular",
        fontSize:0.02*windowWidth,
        
        color: "#495057",
        marginHorizontal:windowWidth*0.1,
        color:"black",
        padding:10,
        borderBottomColor:"black",
        borderBottomWidth:2,

    }

})