
import React, { useState, useRef } from "react";
import { Animated, StyleSheet, Text, View, ScrollView, ImageBackground, Button, TouchableOpacity, Dimensions, Platform } from 'react-native';

import { AntDesign } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import InView from 'react-native-component-inview'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const isPhone = windowWidth < 600


export function Card(props){
    

    const springAnim = useRef(new Animated.Value(props.startPos)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.spring(springAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,

            // return to start
        }).start();
        // Animated.timing(, {
        //     toValue: 1,
        //     duration: 5000,
        // }).start();
    };




    return(
        <InView onChange={(isVisible) => {if(isVisible){fadeIn()}}}>
        <Animated.View

            style={[
                {
                    flexDirection: isPhone ? null : "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }, {
                    transform: [
                        { translateX: springAnim },
                    ]
                },
            ]}>
            <FontAwesome name={props.iconName} size={100} color="black" />

            <View style={{
                margin: 50,
                paddingLeft: "5%",
                alignItems: "center",
                width: isPhone ? null : "40%",

            }}>
                <Text style={{
                    color: "#59626a",
                    fontWeight: "bold",
                    fontSize: 30,
                    fontFamily: "VarelaRound_400Regular",
                    textAlign: "center"
                }}>
                    {props.header}
                </Text>
                <Text style={{
                    color: "#444",
                    fontWeight: "bold",
                    fontSize: 20,
                    fontFamily: "VarelaRound_400Regular",
                    textAlign: "center",
                    width: "100%",

                }}>
                    {props.content}
                </Text>
            </View>

        </Animated.View>
        </InView>
    )
}