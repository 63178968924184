import React, { useState,useRef } from 'react';
import { Animated,View,Image,StyleSheet,Dimensions} from "react-native"

export function LoadingScreen(props) {
    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1,
            useNativeDriver: false
        }).start();
    };

    const fadeOut = () => {
        
        // Will change fadeAnim value to 0 in 3 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: false
        }).start();
    };

    const [isLoading,setLoading] = useState(true)
    if(isLoading){
        return (
            <Animated.Image source={require('../assets/loading.gif')}
                onLayout={() => {
                    fadeIn()
                    setTimeout(() => {
                        console.log("Stopping")
                        setLoading(false);
                    }, 4600);
                    setTimeout(() => {
                        console.log("fading out!")
                        fadeOut()
                    }, 3600);
                }}
                resizeMode='center'
                style={[{
                    width: "100%", height: "100%",
                    backgroundColor: "rgba(254,252,254,1)",


                }, {
                        // Bind opacity to animated value
                        opacity: fadeAnim,
                    },]}
            />
        )
    }else{
        return <View></View>
    }
    
}