import React, { useState, useCallback, useEffect,useRef } from 'react'
import { Animated,Button, TouchableOpacity,View,Text,Dimensions } from 'react-native';
import { GiftedChat } from 'react-native-gifted-chat'
import { Ionicons } from '@expo/vector-icons';
import { useFonts, VarelaRound_400Regular } from '@expo-google-fonts/varela-round';
import { StreamChat } from 'stream-chat';






const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const isPhone = windowWidth < 600

export function ChatBot(props){

    const chatXAnim = useRef(new Animated.Value(70)).current;
    const inX = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.spring(chatXAnim, {
            toValue: (isPhone ? windowWidth * 0.9 : windowWidth * 0.25),
            duration: 1000,
            useNativeDriver: true,

        }).start();
    };
    const outX = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.spring(schatXAnim, {
            toValue: 70,
            duration: 1000,
            useNativeDriver: true,

            // return to start
        }).start();
    };

    var isActive = props.isActive
    if(!isActive){
        return (
            <TouchableOpacity onPress={()=>{
                inX()
                props.onActiveChange(true)

            }}
                style={{ width: chatXAnim, height: "100%",justifyContent:"center",alignItems:"center"}}
            >
                <Ionicons name="ios-chatbubbles-outline" size={40} color="black" />
            </TouchableOpacity>
        )
    }else{
        return (
            
                <ChatBotMain onPressClose={() => {
                    props.onActiveChange(false)

                }}/>
            
        )
    }
    
}

function ChatBotMain(props) {


    let [fontsLoaded] = useFonts({
        VarelaRound_400Regular
    });


    const [waitingReason, setReason] = useState("done")
    const [desc,setDesc] = useState("")
    const [messages, setMessages] = useState([{}]);

    function handleSend(newMessage = []) {
  
        setMessages(GiftedChat.append(messages, newMessage));
    }

    function handleRespond(newMessage = []) {
        setMessages(GiftedChat.append(messages, newMessage));
    }


    useEffect(()=>{
        handleRespond({
            _id: Math.random * 1000,
            text: 'Please describe your problem and we\'ll connect you to an technician soon.',
            createdAt: new Date().getTime(),
            user: {
                _id: 2,
                name: 'FT'
            }
        })
        setReason("desc")
    },[])



    
    //=================
    //   To Be Fixed
    //=================
    return (
        <View
            style={{
                margin:20,
                alignContent:"center",
                alignItems:"center"
            }}
        >
            <Text 
                style={{
                    fontFamily:"VarelaRound_400Regular",
                    alignSelf:"center",
                    textAlign:"center",
                    fontSize:25,
                    fontWeight:"600"
                }}>
                Live Chat Is Coming Soon!
            </Text>
            <Text
                style={{
                    fontFamily: "VarelaRound_400Regular",
                    alignSelf: "center",
                    textAlign: "center",
                    fontSize: 15,
                    
                }}>
                Id ullamco incididunt nulla proident cillum labore labore cillum irure. Aute deserunt ipsum aliquip dolor consectetur est.
            </Text>
            <TouchableOpacity
                onPress={()=>{
                    props.onPressClose()
                }}
                style={{
                    marginTop:10,
                    width:"60%",
                    padding:10,
                    borderRadius:20,
                    borderWidth:2
                }}
            
            >
                <Text
                    style={{
                        fontFamily: "VarelaRound_400Regular",
                        alignSelf: "center",
                        textAlign: "center",
                        fontSize: 20,
                        fontWeight: "600"
                    }}>
                    Ok
                </Text>
            </TouchableOpacity>
        </View>
    )
    return (
        <>
            
            <GiftedChat
                messages={messages}
                onSend={newMessage => handleSend(newMessage)}
                user={{ _id: 1 }}
            />
        </>
    )
}