import React,{useState,useRef} from "react";
import { StyleSheet, Text, View, ScrollView, ImageBackground, Button, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Dimensions } from 'react-native';
import AppLoading from 'expo-app-loading';
import { Hoverable, Pressable, } from 'react-native-web-hover'
import { ref, push, set,onValue, } from "firebase/database";
import { CaesarDressing_400Regular } from "@expo-google-fonts/caesar-dressing"
import { useFonts, VarelaRound_400Regular } from '@expo-google-fonts/varela-round';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useFocusEffect } from '@react-navigation/native';

import { app, database, analytics } from '../firebase/firebase'
import { Header, BlogCard } from "../components/index";
import { ViewBlog } from ".";



const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const isPhone = windowWidth < 600

const Stack = createNativeStackNavigator();

export function Blog({navigation}) {

    const [isView,setView] = useState(false)

    if(isPhone){
        navigation.setOptions({ headerShown: !isView })
    }

    if(!isPhone){
        return(
        <Stack.Navigator>
            <Stack.Screen name="Recent" component={SearchBlog} options={{ headerShown: false }} 
             />
            <Stack.Screen name="View Blog" component={ViewBlog} options={{ headerShown: false }} initialParams={{
                onLoad: function () {
                    setView(true)
                },
                onUnload: function () {
                    setView(false)
                }
            }} />

        </Stack.Navigator>
        )
    }else{
        return (
            <Stack.Navigator>
                <Stack.Screen name="Recent" component={SearchBlog} options={{ headerShown: false }} initialParams={{
                    onLoad: function () {
                        setView(false)
                    },
                    onUnload: function () {
                        setView(true)
                    }
                }}/>
                <Stack.Screen name="View Blog" component={ViewBlog} options={{ headerShown: true }} initialParams={{
                    onLoad: function () {
                        setView(true)
                    },
                    onUnload: function () {
                        setView(false)
                    }
                }} />

            </Stack.Navigator>

        );
    }   
}

function SearchBlog({navigation,route}){
    const [blogs, setBlogs] = useState([])
    const [loading,setLoading] = useState(true)

    const db = database;

    const blogsRef = ref(db, 'blogs');
    
    if (isPhone){
        useFocusEffect(
            React.useCallback(() => {
                if (route.params.onLoad) {
                    route.params.onLoad()
                }


                return () => {

                    if (route.params.onUnoad) {
                        route.params.onUnoad()
                    }
                    // Do something when the screen is unfocused
                    // Useful for cleanup functions
                };
            }, [])
        );
    }
    


    let [fontsLoaded] = useFonts({
        'Navarone-Regular': require('../assets/fonts/Navarone-Regular.ttf'), VarelaRound_400Regular, CaesarDressing_400Regular
    });

    if(loading){
        onValue(blogsRef, (snapshot) => {
            var tmpBlogs = blogs
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();

                childData.key = childKey;
                tmpBlogs.push(childData)
                

            });
            setBlogs(tmpBlogs);

            console.log("blogs")
            console.log(blogs)
            setLoading(false)
        }, {
            onlyOnce: true
        });
    }



    const backgroundColor = "#f8f9fa"


    


    if (!fontsLoaded && !loading) {
        return <AppLoading />;
    } else {
        return (

            <ScrollView style={{backgroundColor:backgroundColor}}>
                <View style={{

                    flex: 1,
                    backgroundColor: '#f8f9fa',
                    alignItems: 'center',
                    height:"100%",
                    width:"100%"

                }}>
                    <Header navigation={navigation} isBlog/>
                


                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height:"100%",
                        width: "100%",
                    }}>

                        <View style={{
                            alignItems: "center",
                            width: isPhone? "90%":"70%",
                            height: "100%"

                        }}>
                            <Text style={{
                                color: "#59626a",
                                fontWeight: "700",
                                fontSize: 45,
                                fontFamily: "Georgia",
                                textAlign: isPhone ? "center" : "flex-start",
                                alignSelf: isPhone?"center":"flex-start"
                            }}>
                                Recent Posts
                            </Text>

                            {/* {blogs.length > 0 && <BlogCard info={blogs[0]}  />} */}
                            {
                                blogs.slice(0).reverse().map((item)=>{
                                    return <BlogCard info={item} onSelect={(key) => {
                                        navigation.navigate('View Blog', {
                                            postId:key
                                        });}} />
                                })
                            }
                            
                        </View>
                    </View>
                        
                    

                 </View>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    point:{
        backgroundColor: "white",
        width: 5,
        height: 5,
        borderRadius: 5,
        margin: 5
    },
    listItem:{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
    },

    listText:{
        color: 'white',
        fontWeight: "500",
        fontSize: 0.013 * windowWidth,
        fontFamily: "VarelaRound_400Regular"
    },
    headerButtonText:{
        fontFamily: "VarelaRound_400Regular",
        fontSize:0.02*windowWidth,
        
        color: "#495057",
        marginHorizontal:windowWidth*0.1,
        color:"black",
        padding:10,
        borderBottomColor:"black",
        borderBottomWidth:2,

    }

})