import React,{useState,useRef} from "react";
import { StyleSheet, Text, View, ScrollView, ImageBackground, Button, TouchableOpacity,Image } from 'react-native';
import { Dimensions } from 'react-native';
import AppLoading from 'expo-app-loading';
import { Hoverable, Pressable, } from 'react-native-web-hover'
import { ref, push, set,onValue,child,get } from "firebase/database";

import { CaesarDressing_400Regular } from "@expo-google-fonts/caesar-dressing"
import { useFonts, VarelaRound_400Regular } from '@expo-google-fonts/varela-round';
import Markdown from 'react-native-simple-markdown'
import { useFocusEffect } from '@react-navigation/native';
import { app, database, } from '../firebase/firebase'
import { Header, BlogCard } from "../components/index";
import { SharedElement } from 'react-native-motion';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const isPhone = windowWidth < 600
import * as Linking from 'expo-linking';


export function ViewBlog({route,navigation}){
    const [title,setTitle] = useState("")
    const [image, setImage] = useState("")
    const [body, setBody] = useState("")
    const [loading,setLoading] = useState(true)

    const db = database;

    const blogsRef = ref(db, 'blogs');
    
    useFocusEffect(
        React.useCallback(() => {
            console.log("screen was focused.")
            if (route.params.onLoad){
                route.params.onLoad()
            }
            

            return () => {
                console.log("screen was unfocused. Reseting Props.")
                setTitle("")
                setImage("")
                setBody("")
                setLoading(true)
                if (route.params.onUnoad) {
                    route.params.onUnoad()
                }
                // Do something when the screen is unfocused
                // Useful for cleanup functions
            };
        }, [])
    );


    let [fontsLoaded] = useFonts({
        'Navarone-Regular': require('../assets/fonts/Navarone-Regular.ttf'), VarelaRound_400Regular, CaesarDressing_400Regular
    });

    if(loading){
       
        const dbRef = ref(database);
        get(child(dbRef, "blogs/" + route.params.postId)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                setTitle(data.title);
                setImage(data.image);
                setBody(data.body);
                setLoading(false)
            } else {
                console.log("No data available at ", child(dbRef, "blogs/" + route.params.postId));
            }
        }).catch((error) => {
            console.error(error);
        });
    }



    const backgroundColor = "#f8f9fa"


    


    if (!fontsLoaded || loading) {
        return <AppLoading />;
    } else {
        return (

            <ScrollView style={{backgroundColor:backgroundColor}}>
                <View style={{

                    flex: 1,
                    backgroundColor: '#f8f9fa',
                    alignItems: 'center',
                    height:"100%",
                    width:"100%"

                }}>
                    <Header navigation={navigation}/>
                    <View style={{ 
                        width:"100%",
                        height:"100%",
                        
                    }}>   
                        
                        <Image source={{ uri: image }} style={{
                            width:"100%",
                            height: windowHeight * 0.6,
                            borderBottomLeftRadius:20,
                            borderBottomRightRadius: 20,
                            borderBottomLeftRadius:20,
                        }} />
                        <View style={{
                            transform: [{ translateY: isPhone ? -(windowHeight * 0.25):-(windowHeight*0.15) }],
                            backgroundColor:"white",
                            padding:20,
                            borderRadius:30,
                            alignSelf:"center",
                            marginBottom: 0
                        }}>
                            <Text style={{ textAlign: "center", fontSize: 40, fontWeight: "200", }}>{title}</Text>
                            <Text style={{ textAlign: "left", fontSize: 20, fontWeight: "200" }}>by Lukas Hahn</Text>
                        </View>
                        <Markdown style={{padding:20}} styles={markdownStyles}>{body.replaceAll("\n", "\n\n")}</Markdown>
                    </View>

                 </View>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    point:{
        backgroundColor: "white",
        width: 5,
        height: 5,
        borderRadius: 5,
        margin: 5
    },
    listItem:{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
    },

    listText:{
        color: 'white',
        fontWeight: "500",
        fontSize: 0.013 * windowWidth,
        fontFamily: "VarelaRound_400Regular"
    },
    headerButtonText:{
        fontFamily: "VarelaRound_400Regular",
        fontSize:0.02*windowWidth,
        
        color: "#495057",
        marginHorizontal:windowWidth*0.1,
        color:"black",
        padding:10,
        borderBottomColor:"black",
        borderBottomWidth:2,

    }

})
const markdownStyles = StyleSheet.create({
    image:{
        width:windowHeight*0.5,
        overflow:"hidden",
        borderRadius:20,
        aspectRatio:1,

    },



    heading1:{
        textAlign:"center",
        fontSize: 35, 
        fontWeight: "200", 
        marginVertical: 10,
    },
    heading2: {
        textAlign: "left",
        fontSize: 25,
        fontWeight: "400",
        marginVertical:10,
    },


    text: {
        fontSize:20,
        fontWeight:"300",
    },

    view:{
        alignSelf:'center',
        width:(isPhone?"98&":"50%"),
        borderLeftWidth:1,
        borderRightWidth:1,
        padding:20,
        borderColor:"#bbb",
        marginHorizontal: (isPhone ? 8 : 50),
        
    }
})