//        Sorry All!
//  You might have better 
//  luck reading the bundle,
//  than reading my code :/


import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import {
  Animated,
  Button,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  Image,
  Pressable
} from "react-native";
import { HomeScreen, ContactUs, Blog, ViewBlog } from './screens/';
import { ChatBot, LoadingScreen } from './components/';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import * as Linking from 'expo-linking';

import AppLoading from "expo-app-loading";
import { Asset } from "expo-asset";
import Constants from "expo-constants";
import * as SplashScreen from "expo-splash-screen";
import * as Updates from "expo-updates";

SplashScreen.preventAutoHideAsync().catch(() => {
  /* reloading the app might trigger some race conditions, ignore them */
});



const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();
const title = (text) =>`${text}`
//options={{ headerTitle: (props) => <Logo {...props} /> }}

const prefix = Linking.createURL('/');

export default function App() {


  const navigationRef = useNavigationContainerRef();


  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const isPhone = windowWidth < 600



  const [dest,setDest] = useState("");
  const [isReady, setReady] = useState(false);

  if(dest == ""){
    Linking.getInitialURL()
      .then((url) => {
        const sUrl = url.toString()
        console.log(sUrl)
        if (!(sUrl.includes("/Home") || sUrl.includes("/Blog") || sUrl.includes("/Contact")) && sUrl.includes("?")) {
          const dest = extractUrlValue("dest", url);
          setDest(dest)

        }




      })
  }

  if(isReady && dest != ""){
    navigationRef.navigate(dest.replaceAll("%20"," "))
  }
  




  const config = {
    screens: {
      Blog: {
        screens: {
          Recent: 'Blog/Recent/:',
          ViewBlog: 'Blog:dbKey',
        },
      },
      HomeScreen: 'Hello',
    },
  };

  const linking = {
    prefixes: [prefix],
  };

  const [isLoading, setLoading] = useState(true);
  const [isChatActive, setChatActive] = useState(false)

  

  if (false) {
    <></>
  } else {
    return (
      <>
      <LoadingScreen/>
      <View
        onPress={() => { setChatActive(false) }}
        style={{
          flex: 1,
          justifyContent: 'center',

        }}>

        <View style={{
          width: isChatActive ? (isPhone ? windowWidth * 0.9 : windowWidth * 0.25) : 70,
          height: isChatActive ? (isPhone ? windowHeight * 0.9 : windowHeight * 0.5) : 70,

          backgroundColor: "#f2f2f2",
          borderRadius: 20,

          position: 'absolute',
          right: 20,
          bottom: 20,
          zIndex: 300,
          borderColor: "#ddd",
          borderWidth: 3,

          alignContent: "center",
          justifyContent: "center",
          overflow: "hidden"

        }}>
          <ChatBot
            isActive={isChatActive}
            onActiveChange={(is) => {

              setChatActive(is)
            }}
          />
        </View>
        <NavigationContainer ref={navigationRef} linking={linking} onReady={() => {
            setReady(true)
        }}>
          {isPhone && <Drawer.Navigator initialRouteName="Home">
            <Drawer.Screen name="Home" component={HomeScreen} options={{ title: title('Reliable IT Suport for Families and more | Familyteks.com') }}/>
            <Drawer.Screen name="Contact Us" component={ContactUs} options={{ title: title('Contact Us | Reliable IT Suport for Families and more | Familyteks.com') }}/>
            <Drawer.Screen name="Blog" component={Blog} options={{ title: title('Blog | Reliable IT Suport for Families and more | Familyteks.com') }}/>
          </Drawer.Navigator>}
          {!isPhone && <Stack.Navigator screenOptions={{ headerShown: false }} >
            <Stack.Screen name="Home" component={HomeScreen} options={{ title: title('FamilyTeks Reliable IT Suport for Families and More | Familyteks.com') }}/>
            <Stack.Screen name="Contact Us" component={ContactUs} options={{ headerShown: false }} options={{ title: title('Contact Us | FamilyTeks Reliable IT Suport for Families and more | Familyteks.com') }} />
            <Stack.Screen name="Blog" component={Blog} options={{ headerShown: false }} options={{ title: title('Blog | FamilyTeks Reliable IT Suport for Families and more | Familyteks.com') }} />
            <Stack.Screen name="View Blog" component={ViewBlog} options={{ headerShown: false }} options={{ title: title('Blog | FamilyTeks Reliable IT Suport for Families and more | Familyteks.com') }} />
          </Stack.Navigator>}
        </NavigationContainer>
      </View>
      </>
    );
  }
}







const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#0e2e61',
    alignItems: 'center',
    justifyContent: 'center',
  },
});


function extractUrlValue(key, url) {
  if (typeof (url) === 'undefined')
    url = window.location.href;
  var match = url.match('[?&]' + key + '=([^&]+)');
  return match ? match[1] : null;
}