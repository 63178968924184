import React, { useState, useRef } from "react";
import { StyleSheet, Text, View, ScrollView, Image, Button, TouchableOpacity, Dimensions} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import AppLoading from 'expo-app-loading';

import { useFonts, VarelaRound_400Regular } from '@expo-google-fonts/varela-round';


import { SharedElement } from 'react-native-motion';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const isPhone = windowWidth < 600

export function BlogCard(props){
    console.log(props)
    const {title,subtitle,image,key,body} = props.info;

    console.log("Recive PROPS!", key)

    let [fontsLoaded] = useFonts({
        VarelaRound_400Regular
    });

    if (fontsLoaded){
        return (
            <TouchableOpacity onPress={() => { props.onSelect(key)}} style={styles.container}>
                
                    <Image source={{ uri: image }} style={styles.imageContainer}/>
                


                <View style={styles.textContainer}>
                    <Text style={styles.title}> {title}</Text>
                    <Text style={styles.subTitle}> {body.substring(0, 300).replace(/^!.*\n?/gm, '').replace(/^\[.*\n?/gm, '').replace(/^#.*\n?/gm, '').replaceAll("*", "").trim()}</Text>
                </View>
                    

                
            </TouchableOpacity>

        )
    } else {
        return (<View><Text>Loading</Text></View>)
    }
    
}

const styles = StyleSheet.create({
    container:{
        width:"100%",
        borderTopLeftRadius:40,
        borderTopRightRadius:40,
        borderBottomLeftRadius:40,
        borderBottomRightRadius:40,
        borderColor:"#dddddd",
        borderWidth:2,
        alignItems:"center",
        justifyContent:"center",
        overflow:"hidden",
        margin:20
    },

    imageContainer:{
        width:"100%",
        height: isPhone ?null :500,
        aspectRatio:1

    },
    textContainer:{
        alignSelf:"center",

        // borderBottomLeftRadius: 20,
        // borderBottomWidth:2,
        // borderLeftWidth:2,
        // borderRightWidth:2,
        // borderBottomColor:"#bbb",
        // borderLeftColor: "#bbb",
        // borderRightColor: "#bbb"

    },

    title:{
        color:"black",
        margin:20,
        marginBottom:0,
        fontFamily:"Georgia",
        fontSize:35,
        fontWeight:"200",
        textAlign:"center"

    },

     subTitle: {
        margin:20,
        marginVertical:4,
        fontFamily: "VarelaRound_400Regular",
        fontSize: 19,
        fontWeight: "200",
        color:"#59626a",
        textAlign:"center"

    }

    

})