
import { initializeApp } from "firebase/app";
import { getAnalytics,logEvent } from "firebase/analytics";
import { getDatabase,ref, onValue,set,get,push} from "firebase/database";
import uuid from 'react-native-uuid';


// ===Constants===
const userRefString = '/Users/'
const firebaseConfig = {
    apiKey: "AIzaSyDx63Xw4EZ1ANU2Xl_6GowPMaqOJOm_KxY",
    authDomain: "familytek-url.firebaseapp.com",
    projectId: "familytek-url",
    storageBucket: "familytek-url.appspot.com",
    messagingSenderId: "394225776328",
    appId: "1:394225776328:web:f166c10cc88483488ed7fd",
    measurementId: "G-YGM7QG9YL9"
};

// ===Initilizations===

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics();


// ===Helper Functions===

function addUser(userData) {
    const { email, phone, name} = userData
    const nameSanitized = (name.split(" ")[0] + name.split(" ")[name.split(" ").length - 1]).replaceAll(" ", "").toLowerCase()

    const usersRefByUUID = ref(database, userRefString+"byUUID/")
    const usersRefByName = ref(database, userRefString + "byName/")
    const usersRefByPhone = ref(database, userRefString + "byPhone/")
    


    get(ref(database, "/byName/")).then((snapshot) => {
        if (snapshot.hasChild(nameSanitized)) {
            logEvent(analytics, 'add_user', {
                event_name: "add_user",
                name: name,
                nameSanitized: nameSanitized,
                email: email,
                phone: phone,
                isNewUser:false
            });
        } else {
            //User dose not exist
            const id = uuid.v1()
            logEvent(analytics, 'add_user', {
                event_name: "add_user",
                name: name,
                nameSanitized: nameSanitized,
                email: email,
                phone: phone,
                isNewUser: true,
                uuid:id,
            });

            console.log("Setting User")
            console.log("- " + id)
            console.log("   name:" + name)
            console.log("   email: " + email)
            console.log("   phone: " + phone)

            console.log("Adding to Directory")
            console.log("-byPhone")
            console.log("   -" + phone)
            console.log("       id: " + id)
            console.log("       email: " + email)
            console.log("       name: " + name)
            console.log("\n\n")
            console.log("-byName")
            console.log("   -" + nameSanitized)
            console.log("       id: " + id)
            console.log("       email: " + email)
            console.log("       phone: " + phone)

            set(ref(database, usersRefByUUID.key + "/" + id), {
                name: name,
                email: email,
                phone: phone
            });

            set(ref(database, usersRefByName.key + "/" + nameSanitized), {
                id: id,
                email: email,
                phone: phone
            });
        }
    }).catch((error) => {
        console.error(error);
    });


}

function addProblem(problemData) {
    const { email, phone, name, desc } = problemData


    console.log("Adding Problem")
    console.log("   name:" + name)
    console.log("   email: " + email)
    console.log("   phone: " + phone)
    console.log("   desc: " + desc)

    
    const newOrderRef = push(ref(database, "orders/"))
    set(newOrderRef, {
        name: name,
        email: email,
        phone: phone,
        desc:desc
    });




}

// ===Exports===
export {app,database,
  addUser,
    analytics, addProblem
}