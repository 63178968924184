import { Dimensions,StyleSheet, Text, View, ScrollView, ImageBackground, Button, TouchableOpacity } from 'react-native';
import React, { useState,useRef } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { Popable } from 'react-native-popable';
import { Link } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const isPhone = windowWidth < 600





export function Header(props){
    const [showPopover, setShowPopover] = useState(false)

    if (isPhone){
        return (
            <></>
        )
    }else{
        return (
            <View style={{ zindex:300,height: windowHeight * 0.13, justifyContent: "center", flexDirection: "row", alignItems: "center", borderBottomColor: "#bbb", borderBottomWidth: 2, width: "100%" }}>

                
                <Link to={{ screen: 'Home'}}>
                    <Text style={[styles.headerButtonText, { borderWidth: props.isHome ? 2 : null }]}>Home</Text>
                </Link>

                <Link to={{ screen: 'Blog' }}>
                    <Text style={[styles.headerButtonText, { borderWidth: props.isBlog ? 2 : null }]}>Blog</Text>
                </Link>
                <Link to={{ screen: 'Contact Us'}}>
                    <Text style={[styles.headerButtonText, { borderWidth: props.isContact ? 2 : null }]}>Contact Us</Text>
                </Link>
            </View>
        )
    }

}



function PopoverButton(){

    return(
        <TouchableOpacity
            onPress={() => { props.navigation.navigate("Contact Us") }}
        >
            <Text style={[styles.headerButtonText, { borderWidth: false ? 2 : null }]}>Services</Text>
            {/* <View style={{ zindex:300,position: 'absolute', top: 100, right:20,left:20,bottom:-300, backgroundColor: "red" }}>

            </View> */}
        </TouchableOpacity>
    )
}
// {/* <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
//     <Text>Centered text</Text>
// </View> */}


const styles = StyleSheet.create({
    point: {
        backgroundColor: "white",
        width: 5,
        height: 5,
        borderRadius: 5,
        margin: 5
    },
    listItem: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
    },

    listText: {
        color: 'white',
        fontWeight: "500",
        fontSize: 0.013 * windowWidth,
        fontFamily: "VarelaRound_400Regular"
    },
    headerButtonText: {
        fontFamily: "VarelaRound_400Regular",
        fontSize: 0.017 * windowWidth,

        color: "#495057",
        marginHorizontal: windowWidth * 0.07,
        color: "black",
        padding: 10,
        borderColor: "black",
        borderBottomWidth: 2,

    }

})