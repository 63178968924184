import React,{useState,useRef, useEffect} from "react";
import { Animated,StyleSheet, Text, View, ScrollView, ImageBackground, Button, TouchableOpacity, Dimensions,Platform } from 'react-native';

//Expo
import { LinearGradient } from 'expo-linear-gradient';
import AppLoading from 'expo-app-loading';

//Fonts
import { CaesarDressing_400Regular } from "@expo-google-fonts/caesar-dressing"
import { useFonts } from '@expo-google-fonts/varela-round';
import { VarelaRound_400Regular } from '@expo-google-fonts/varela-round';
 

//Other
import { DataTable } from 'react-native-paper';


//Components
import { Header,Card } from "../components/index";

//Vector Icons
import { AntDesign } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';


//constants
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const isPhone = windowWidth<600
console.log(isPhone ? "running on phone" : "running on computer or ipad")
export function HomeScreen({navigation,route}){
    
     
    


    const [viewY,setViewY] = useState(0);


    const springAnim = useRef(new Animated.Value(-windowWidth)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.spring(springAnim, {
            toValue: 0,
            duration: 2000,
            useNativeDriver: true,

            // return to start
        }).start();
        // Animated.timing(, {
        //     toValue: 1,
        //     duration: 5000,
        // }).start();
    };


    useEffect(() => { fadeIn()})

    let [fontsLoaded] = useFonts({
        'Navarone-Regular': require('../assets/fonts/Navarone-Regular.ttf'),VarelaRound_400Regular,CaesarDressing_400Regular
    });


    const bottomColor = '#3b3b3b'

    if (false) { // Fixes weird Font bug.

        return <Text >Loading</Text>;
    } else {
        return (

            <ScrollView style={{ backgroundColor: '#f8f9fa' }} >
            <View style={{
                
                flex: 1,
                backgroundColor: '#f8f9fa',
                alignItems: 'center',
                zindex: 300,
            }}>
                <Header navigation={navigation} isHome style={{ zindex: 300,}}/>
                {/* ["f8f9fa","e9ecef","dee2e6","ced4da","adb5bd","6c757d","495057","343a40","212529"] */}


                <LinearGradient

                    colors={["#bbb",'#3b3b3b']}
                    style={{
                        zIndex:10,
                        width:"90%",
                        height:isPhone?250:windowHeight*0.80,
                        borderRadius:20,
                        marginTop:30,
                        paddingTop: isPhone?null:30,
                        alignItems:"center",
                        borderBottomLeftRadius: isPhone ? 20:0,
                        borderRadius: 20,
                    

                }}>

                    <CImageBackground 
                        source={isPhone?null:require("../assets/backgrounds/homescreen_back.png")} 
                        imageStyle={{
                            resizeMode: !isPhone ? 'cover':'stretch',
                        }}
                        style={{
                            zindex: 1,
                            width: "100%", height: "100%",
                            paddingTop: 30,
                            alignItems: "center",
                        }}>
                    <View 
                                style={[
                                    {
                                        backgroundColor: "white",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: (isPhone ? "90%" : "40%"),
                                        borderRadius: 20,
                                        padding: 8
                                    }, {
                                        transform: [
                                            { translateX: springAnim },
                                        ]
                                    },
                                ]}>
                            <View style={{alignItems:"center"}}>
                                <Text style={{
                                        fontWeight:"100",
                                        textAlign:"center",
                                        fontSize: isPhone ? 50 :70,
                                        fontFamily: "Navarone-Regular",
                                        width:"100%"
                                }}>
                                        Family
                                </Text>
                                <Text style={{
                                        color: "#495057",
                                        fontSize: isPhone ? 50 : 70,
                                        textAlign: "center",
                                        fontFamily: "Navarone-Regular",
                                        width: "100%"
                                }}>
                                    Teks
                                </Text>
                               <Text style={{
                                        textAlign: "center",
                                        fontWeight:"400",
                                        fontSize: isPhone ? 15 : 20,
                                        fontFamily: "VarelaRound_400Regular",
                                        width:"100%"
                                }}>
                                        A   T e c h   S u p p o r t   S e r v i c e
                                </Text> 
                            </View>



                    </View>

                    

                    {!isPhone && (<>
                    <View style={{ height: windowHeight * 0.05 }} />
                    <View style={styles.listItem} >
                        <View style={styles.point} />
                        <Text style={styles.listText}>Ut esse est ad cillum voluptate laboris elit ad quis cillum.</Text>
                    </View>

                    <View style={styles.listItem} >
                        <View style={styles.point} />
                        <Text style={styles.listText}>Officia consectetur nisi ex nulla deserunt.</Text>
                    </View>

                     <View style={styles.listItem} >
                        <View style={styles.point} />
                        <Text style={styles.listText}>Voluptate culpa ad consequat duis ut Lorem labore est laboris.</Text>
                    </View>

                     <View style={styles.listItem} >
                        <View style={styles.point} />
                        <Text style={styles.listText}>Dolor ad quis do magna.</Text>
                    </View></>)}
                    
                     </CImageBackground >
                </LinearGradient>

                <View style={{
                    marginTop: isPhone ? 20 : 0,
                    width: "90%",
                    flexDirection:isPhone?null:"row",
                    marginBottom: 100
                }}>
                    <BImageBackground 
                    source={require("../assets/stock/typing.jpg")}
                    style={{
                        height: "100%",
                        width: isPhone ? "100%" :"50%",
                        backgroundColor: bottomColor,
                        padding:20,
                        paddingTop:50,
                        borderRadius: isPhone ? 20 : null,
                        borderBottomLeftRadius:20,
                        borderBottomRightRadius:20,
                        height:null,
                        overflow: "hidden",

                    }}>
                            <Text style={{
                                color: "white",
                                fontWeight: "bold",
                                fontSize: 30,
                                fontFamily: "VarelaRound_400Regular",
                                textAlign:"center"
                            }}>
                                Our Services.
                            </Text>

                            <View style={[styles.listItem,{alignSelf:"flex-start",paddingVertical:20}]} >
                                <View style={[styles.point, { backgroundColor:"#dee2e6"}]} />
                                <Text style={[styles.listText, { fontSize: 25, color:"#dee2e6"}]}>Simple and quick tech support from an experienced team.</Text>
                            </View>

                            <View style={[styles.listItem, { alignSelf: "flex-start", paddingVertical: 20 }]} >
                                <View style={[styles.point, { backgroundColor: "#dee2e6" }]} />
                                <Text style={[styles.listText, { fontSize: 25, color: "#dee2e6" }]}>Avalible anytime for any tech emergencies.</Text>
                            </View>

                            <View style={[styles.listItem, { alignSelf: "flex-start", paddingVertical: 20 }]} >
                                <View style={[styles.point, { backgroundColor: "#dee2e6" }]} />
                                <Text style={[styles.listText, { fontSize: 25, color: "#dee2e6" }]}>Were we unable to fix the problem? Well pay for it.</Text>
                            </View>

                           

                            
                    </BImageBackground>
                    <View style={{
                        height:"100%",
                        width: isPhone ? "100%" : "50%",
                        backgroundColor: isPhone ? null :bottomColor

                    }}>
                    <View style={{
                        borderTopLeftRadius:20,
                        height:"100%",
                        width: "100%",
                        backgroundColor: "#f6f7f9",
                        alignItems:'center'
                    }}>
                            <Text style={{
                                marginTop:20,
                                color: "#59626a",
                                fontWeight: "bold",
                                fontSize: 30,
                                fontFamily: "VarelaRound_400Regular",
                                textAlign: "center",
                                paddingBottom:40
                                
                            }}>
                                Deserunt veniam ut do anim.
                            </Text>
                                <DataTable style={{ width:isPhone?null:"70%" ,backgroundColor: null, borderRadius: 20, alignItems: "center",borderWidth:2,overflow:"hidden",borderColor:"black"}}>
                                <DataTable.Header style={{ width: "100%"}}>
                                    <DataTable.Title >One Time</DataTable.Title>
                                    <DataTable.Title>Membership</DataTable.Title>
                                </DataTable.Header>

                                <DataTable.Row style={{ width: "100%"}}>
                                    <DataTable.Cell>
                                        $14/hr
                                    </DataTable.Cell>
                                    <DataTable.Cell>$14/mo</DataTable.Cell>

                                </DataTable.Row>

                                <DataTable.Row style={{ width: "100%"}}>
                                    <DataTable.Cell>Feature</DataTable.Cell>
                                    <DataTable.Cell>Up to 5hrs a Month</DataTable.Cell>

                                </DataTable.Row>

                                <DataTable.Row style={{ width: "100%"}}>
                                    <DataTable.Cell>Feature</DataTable.Cell>
                                    <DataTable.Cell>Feature</DataTable.Cell>

                                </DataTable.Row>

                            </DataTable>
                    </View>
                    </View>
                </View>
                
                <View 
                    onLayout={event => {
                        const { layout } = event.nativeEvent;
                        setViewY(layout.y)
                    }}
                    style={{
                        height:500,
                        width: "100%",
                        //backgroundColor: "rgba(108,117,125,1)",
                        alignItems:"center",
                    }}
                >

                        <Text style={{
                            color: "#59626a",
                            fontWeight: "700",
                            fontSize: 45,
                            fontFamily: "Georgia",
                            textAlign: "center" 
                        }}>Why Chose Us?</Text>

                        <Card 
                        
                            startPos={windowWidth}
                            header={"Amet deserunt voluptate"}
                            content={"Commodo elit labore deserunt mollit veniam. Incididunt aliqua pariatur commodo aliqua commodo id ullamco duis quis elit laboris. Ea sint sunt commodo occaecat sit reprehenderit deserunt cupidatat deserunt ad minim deserunt voluptate pariatur."}
                            iconName={"phone"}
                        />
                        <Card
                            startPos={-windowWidth}
                            header={"Id mollit culpa ullamco ea et."}
                            content={"Commodo elit labore deserunt mollit veniam. Incididunt aliqua pariatur commodo aliqua commodo id ullamco duis quis elit laboris. Ea sint sunt commodo occaecat sit reprehenderit deserunt cupidatat deserunt ad minim deserunt voluptate pariatur."}
                            iconName={"keyboard-o"}
                        />
                        <Card
                            startPos={windowWidth}
                            header={"Sirure id mollit culpa et."}
                            content={"Commodo elit labore deserunt mollit veniam. Incididunt aliqua pariatur commodo aliqua commodo id ullamco duis quis elit laboris. Ea sint sunt commodo occaecat sit reprehenderit deserunt cupidatat deserunt ad minim deserunt voluptate pariatur."}
                            iconName={"gears"}
                        />
                        <Card
                            startPos={-windowWidth}
                            header={"Sirure id mollit culpa et."}
                            content={"Commodo elit labore deserunt mollit veniam. Incididunt aliqua pariatur commodo aliqua commodo id ullamco duis quis elit laboris. Ea sint sunt commodo occaecat sit reprehenderit deserunt cupidatat deserunt ad minim deserunt voluptate pariatur."}
                            iconName={"apple"}
                        />
                </View>
                
            </View>
            </ScrollView>
        )
    }
}

function CImageBackground(props){
    if(isPhone){
        return (
            <View
                
                style={{
                    ...props.style
                }}>
                {props.children}
            </View>
        )
    }else{
        return (
            <ImageBackground
                source={props.source}
                imageStyle={{
                    ...props.imageStyle
                }}
                style={{
                    ...props.style
                }}>
                {props.children}
            </ImageBackground>
        )
    }
}

function BImageBackground(props) {
    if (!isPhone) {
        return (
            <View

                style={{
                    ...props.style
                }}>
                {props.children}
            </View>
        )
    } else {
        return (
            <ImageBackground
                source={props.source}
                imageStyle={{
                    ...props.imageStyle
                }}
                style={{
                    ...props.style
                }}>
                {props.children}
            </ImageBackground>
        )
    }
}

const styles = StyleSheet.create({
    point:{
        backgroundColor: "white",
        width: 7,
        height: 7,
        borderRadius: 7,
        margin: 5
    },
    listItem:{
        width: isPhone? "90%": null,
        flexDirection: "row",
        alignItems: "center",
        alignSelf:"center",
        justifyContent: "center",
        margin: 10,
    },

    listText:{
        color: 'white',
        fontWeight:"500",
        fontSize: isPhone? 20:0.02 * windowHeight,
        fontFamily: "VarelaRound_400Regular"
    },
    headerButtonText:{
        fontFamily: "VarelaRound_400Regular",
        fontSize:0.02*windowWidth,
        
        color: "#495057",
        marginHorizontal:windowWidth*0.1,
        color:"black",
        padding:10,
        borderBottomColor:"black",
        borderBottomWidth:2,

    }

})